<template>
  <div class="service-item">
    <author :info="data.author" />
    <div
      class="item-info"
      @click="
        jumpPage({ name: 'detail', params: { id: data.serviceInfo.pkId } })
      "
    >
      <h2
        v-html="
          lightHeight
            ? highlighted(
                data.serviceInfo.title.length > 80
                  ? data.serviceInfo.title.slice(0, 80)
                  : data.serviceInfo.title,
                keyword
              )
            : data.serviceInfo.title.length > 80
            ? data.serviceInfo.title.slice(0, 80)
            : data.serviceInfo.title
        "
      ></h2>
      <label class="item-info-child">
        <img
          v-if="data.serviceInfo.thumb"
          class="thumbImg"
          :src="formartImageUrl(data.serviceInfo.thumb)"
        />
        <i v-else class="thumbImg noimg">Melinked </i>
        <div class="desc_tag">
          <p
            class="desc"
            v-html="
              lightHeight
                ? highlighted(data.serviceInfo.desc, keyword)
                : data.serviceInfo.desc
            "
          ></p>
          <ul class="tags" v-if="data.serviceInfo.tags.length">
            <li
              v-for="(tag, key) in data.serviceInfo.tags"
              :key="key"
              v-html="lightHeight ? highlighted(tag, keyword) : tag"
            ></li>
          </ul>
        </div>
      </label>
    </div>
    <action :action-data="data.actionData"></action>
  </div>
</template>
<script>
import author from "@/components/business/web-site/author";
import action from "@/components/business/web-site/action";
export default {
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    lightHeight: {
      type: Boolean,
      default: false,
    },
    keyword: {
      type: String,
      default: "",
    },
  },
  components: {
    author,
    action,
  },
  created() {},
};
</script>
<style lang="stylus">
.service-item {
  padding: 1%;
  background: #ffffff;
  display: inline-block;

  // max-width: 620px;

  // border-radius: 5px;
  .item-info {
    overflow: hidden;
    word-break: break-word;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    h2 {
      font-size: 15px;
      overflow: hidden;
    }

    .item-info-child {
      display: flex;
      flex-flow: row;
      align-items: flex-start;

      .thumbImg {
        width: 100px;
        height: 80px;
        min-width: 100px;
        object-fit: cover;
        display: inline-block;
        margin: 0 0.5rem 0 0;
        border-radius: 3px;

        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }

        &.noimg {
          background: #eeeeee;
          font-style: normal;
          line-height: 80px;
          text-align: center;
          color: #dddddd;
          font-weight: 800;
          font-size: 0.98rem;
        }
      }

      .desc_tag {
        .desc {
          font-size: 13px;
          cursor: pointer;
          color: #666666;
          min-width: 300px;
          max-height: 80px;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          // height: 35px;
        }

        .tags {
          height: 30px;
          margin: 0 0 0.5rem 0;
          overflow: hidden;

          li {
            color: #666666;
            border: 1px solid #33cc66;
            border-radius: 50px;
            display: inline-block;
            float: left;
            padding: 0.15rem 0.5rem;
            margin: 0.25rem 0.25rem 0;
            font-size: 12px;
            max-height: 22px;
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>
