var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "service-item" },
    [
      _c("author", { attrs: { info: _vm.data.author } }),
      _c(
        "div",
        {
          staticClass: "item-info",
          on: {
            click: function($event) {
              return _vm.jumpPage({
                name: "detail",
                params: { id: _vm.data.serviceInfo.pkId }
              })
            }
          }
        },
        [
          _c("h2", {
            domProps: {
              innerHTML: _vm._s(
                _vm.lightHeight
                  ? _vm.highlighted(
                      _vm.data.serviceInfo.title.length > 80
                        ? _vm.data.serviceInfo.title.slice(0, 80)
                        : _vm.data.serviceInfo.title,
                      _vm.keyword
                    )
                  : _vm.data.serviceInfo.title.length > 80
                  ? _vm.data.serviceInfo.title.slice(0, 80)
                  : _vm.data.serviceInfo.title
              )
            }
          }),
          _c("label", { staticClass: "item-info-child" }, [
            _vm.data.serviceInfo.thumb
              ? _c("img", {
                  staticClass: "thumbImg",
                  attrs: {
                    src: _vm.formartImageUrl(_vm.data.serviceInfo.thumb)
                  }
                })
              : _c("i", { staticClass: "thumbImg noimg" }, [
                  _vm._v("Melinked ")
                ]),
            _c("div", { staticClass: "desc_tag" }, [
              _c("p", {
                staticClass: "desc",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.lightHeight
                      ? _vm.highlighted(_vm.data.serviceInfo.desc, _vm.keyword)
                      : _vm.data.serviceInfo.desc
                  )
                }
              }),
              _vm.data.serviceInfo.tags.length
                ? _c(
                    "ul",
                    { staticClass: "tags" },
                    _vm._l(_vm.data.serviceInfo.tags, function(tag, key) {
                      return _c("li", {
                        key: key,
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.lightHeight
                              ? _vm.highlighted(tag, _vm.keyword)
                              : tag
                          )
                        }
                      })
                    }),
                    0
                  )
                : _vm._e()
            ])
          ])
        ]
      ),
      _c("action", { attrs: { "action-data": _vm.data.actionData } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }