<template>
  <div class="author">
    <div class="left">
      <img
        v-if="info.thumb"
        class="thumb"
        :src="formartImageUrl(info.thumb)"
        @click="routeJump({ name: 'personalCenter', params: { id: info.providerId } })"
      />
      <i v-else class="noThumb el-icon-user-solid"></i>
      <span
        class="name"
        @click="routeJump({ name: 'personalCenter', params: { id: info.providerId } })"
      >
        {{ info.name }}
      </span>
      <img
        v-if="info.countryCode && info.countryCode !== '0'"
        class="country"
        :src="getCountryIcon(info.countryCode)"
      />
    </div>
    <div class="time right">{{ info.time }}</div>
  </div>
</template>
<script>
export default {
  name: "Author",
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>
<style lang="stylus" scoped>
.author {
  display: flex;
  flex-flow: row;
  // margin-bottom: 20px;
  min-width: 250px;

  .left {
    flex: 5;
    display: flex;
    flex-flow: row;
    align-items: center;

    .thumb {
      width: 24px;
      height: 24px;
      border-radius: 24px;
      object-fit: cover;
    }

    .name {
      max-width: 200px;
      margin: 0 0.5rem;
      font-size: 12px;
      color: #222222;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .country {
      width: 15px;
      margin: 0 0.5rem;
    }

    .noThumb {
      background: #eeeeee;
      width: 24px;
      height: 24px;
      border-radius: 24px;
      display: block;
      background: #f5f5f5;
      text-align: center;
      line-height: 24px;

      &:before {
        font-size: 15px;
        color: #999999;
      }
    }
  }

  .right {
    flex: 3;
  }

  .time {
    color: #999999;
    font-size: 12px;
    word-break: keep-all;
    min-width: 120px;
    text-align: right;
  }
}
</style>
