var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "author" }, [
    _c("div", { staticClass: "left" }, [
      _vm.info.thumb
        ? _c("img", {
            staticClass: "thumb",
            attrs: { src: _vm.formartImageUrl(_vm.info.thumb) },
            on: {
              click: function($event) {
                return _vm.routeJump({
                  name: "personalCenter",
                  params: { id: _vm.info.providerId }
                })
              }
            }
          })
        : _c("i", { staticClass: "noThumb el-icon-user-solid" }),
      _c(
        "span",
        {
          staticClass: "name",
          on: {
            click: function($event) {
              return _vm.routeJump({
                name: "personalCenter",
                params: { id: _vm.info.providerId }
              })
            }
          }
        },
        [_vm._v("\n      " + _vm._s(_vm.info.name) + "\n    ")]
      ),
      _vm.info.countryCode && _vm.info.countryCode !== "0"
        ? _c("img", {
            staticClass: "country",
            attrs: { src: _vm.getCountryIcon(_vm.info.countryCode) }
          })
        : _vm._e()
    ]),
    _c("div", { staticClass: "time right" }, [_vm._v(_vm._s(_vm.info.time))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }